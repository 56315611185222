<template>
  <Tooltip
    :text="tooltipText"
    v-if="event.eventType !== 'REPLAY'"
  >
    <span
      @click.stop="setEventForMapping(event)"
      :class="[
        'icon-wrapper',
        { 'icon-wrapper--not-mapped': !event.hasExternalPrices },
      ]"
    >
      <Icon :name="event.hasExternalPrices ? 'link-not-broken' : 'link-broken'" />
    </span>
  </Tooltip>
  <div v-else>
    -
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { includes } from 'lodash';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Tooltip,
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const tooltipText = computed(() => (props.event.hasExternalPrices ? 'Event mapped' : 'Event not mapped'));
    const hasInternalOpsRole = computed(() => includes(store.getters.userRoles, 'INTERNAL_OPS'));

    const setEventForMapping = (event) => {
      if (event.hasExternalPrices || !hasInternalOpsRole.value) return;
      const eventData = {
        sportLabel: event.sport.sportLabel,
        row: {
          id: event.eventId,
          name: event.eventName,
          competitionName: event.competition.competitionName,
          startsAt: event.startsAt,
        },
      };
      store.dispatch('setEventListMappingEvent', eventData);
    };

    return {
      tooltipText,
      setEventForMapping,
    };
  },
};
</script>

<style lang="scss">
.event-table .table-body .table-cell.table-cell--mapped {
  .tooltip .tooltip-element {
    width: 16px;
    height: 16px;
  }

  .icon-wrapper {
    .icon path {
      fill: $black;
    }
  }

  .icon-wrapper.icon-wrapper--not-mapped {
    .icon path {
      fill: $error500;
    }
  }
}
</style>
